import { Form, Formik } from 'formik';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAsyncError } from '../../common/error/useAsyncError';
import { ConfigContext } from '../configuration/ConfigContext';
import BooleanCheckBox from '../forms/BooleanCheckBox';
import Button from '../forms/Button';
import TextField from '../forms/TextField';
import { optionalEmailValidator } from '../forms/validators';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { PropertyAssociationSettings } from './models/PropertyAssociationSettings';
import { getPropertyAssociationSettings, updatePropertyAssociationSettings } from './settingsService';

const validationSchema = Yup.object().shape({
    email: optionalEmailValidator,
    requireMembership: Yup.boolean(),
})

const GeneralSettings: FunctionComponent = () => {
    const throwAsync = useAsyncError();
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociation, currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [error, setError] = useState<string | undefined>();
    const [propertyAssociationSettings, setPropertyAssociationSettings] = useState<PropertyAssociationSettings>();

    const fetchSettings = () => {
        getPropertyAssociationSettings(
            apiBaseUrl, 
            currentPropertyAssociationId,
            ({ email, requireMembership }) => setPropertyAssociationSettings({ email: email ?? '', requireMembership: requireMembership ?? false}),
            () => throwAsync(new Error('Det gick inte att hämta inställningarna'))
        )
    }

    useEffect(() => {
        fetchSettings()
    }, []);

    const handleSubmit = ({ email, requireMembership }: PropertyAssociationSettings) => {
        updatePropertyAssociationSettings(
            apiBaseUrl, 
            currentPropertyAssociationId, 
            { email, requireMembership },
            () => fetchSettings(),
            () => setError('Det gick inte att uppdatera inställningarna')
        )
    }

    if (!propertyAssociationSettings) {
        return null;
    }

    return (
        <Formik 
            initialValues={propertyAssociationSettings}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({dirty, errors, isValid, touched, values}) => (
                <Form>
                    <PaperLayout heading='Allmänna inställningar' buttons={createButton(dirty, isValid)}>
                        <strong className="mb-2 d-block">Inställningar för {currentPropertyAssociation?.name}</strong>
                        <BooleanCheckBox name="requireMembership" label="Kräv manuellt hanterad åtkomst för att boka resurser och se anslagstavlan" />

                        <strong className="mt-5 mb-2 d-block">Inställningar för styrelsen</strong>
                        <TextField name="email" label="E-postadress" width={50}  {...{errors, touched, values}} />

                        {error && <div className="alert alert-danger mt-5">{error}</div>}
                    </PaperLayout>
                </Form>
            )}
        </Formik>
    )
}

function createButton(dirty: boolean, isValid: boolean) {
    return <Button additionalClassName="btn-lg" type="submit" disabled={!dirty || !isValid} text={dirty && isValid ? 'Spara' : 'Sparad'} />
}

export default withPropertyAssociation(GeneralSettings, false)