import cx from 'classnames';
import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AssetListItem } from '../assets/models/AssetListItem';
import { AuthContext } from '../authentication/AuthContext';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withWaitForPropertyAssociation } from '../propertyAssociation/withWaitForPropertyAssociation';
import { AssetStatus } from '../assets/assetConstants';
import { isDraft, isUnpublished } from '../assets/assetHelpers';
import { useAssets } from '../assets/assetService';
import MobileMenuSection from './MobileMenuSection';

const routes = [
    { prefix: '/bookings', text: 'Bokningar' },
    { prefix: '/assets', text: 'Resurser' },
]

const MobileNavigation: FunctionComponent = () => {
    const { isAuthenticated, signinRedirect } = useContext(AuthContext);
    const { allPropertyAssociations, currentPropertyAssociation, setCurrentArea, setCurrentPropertyAssociation, isAdmin, isGuest } = useContext(PropertyAssociationContext);
    const { assets, isLoading } = useAssets();
    const { pathname: currentPath } = useLocation();
    const [ expanded, setExpanded] = useState(false);
    const [ section, setSection ] = useState('');

    useEffect(() => {
        setExpanded(false);
        routes.map(x => {
            if(currentPath.startsWith(x.prefix)) {
                setSection(x.text);
                return;
            }
        });
    }, [currentPath])

    const signIn = (event) => {
        event.preventDefault();
        signinRedirect();
    }

    const resetCurrentPropertyAssociation = useCallback(() => {
        setCurrentPropertyAssociation(undefined);
        setCurrentArea(undefined);
    }, [setCurrentArea, setCurrentPropertyAssociation]);

    const hasMultiplePropertyAssociations = allPropertyAssociations && allPropertyAssociations.length > 1;

    return (
        <Fragment>
            <button className={cx(['mobile-menu', {'mobile-menu--expanded': expanded}])} onClick={() => setExpanded(!expanded)}>
                <div className="mobile-menu__logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 35.578" width="36" height="32"><path d="m39.648 16.814-4.067-3.561V3.332a1.112 1.112 0 0 0-1.112-1.112h-6.672a1.112 1.112 0 0 0-1.112 1.112v2.137L20.746.274a1.112 1.112 0 0 0-1.464 0L.38 16.814a1.112 1.112 0 0 0 1.464 1.674l2.6-2.281v16.035a3.339 3.339 0 0 0 3.336 3.336h24.465a3.339 3.339 0 0 0 3.336-3.336V16.207l2.6 2.279a1.112 1.112 0 0 0 1.464-1.672Zm-6.291 15.428a1.112 1.112 0 0 1-1.112 1.111H7.783a1.112 1.112 0 0 1-1.112-1.112v-17.98L20.014 2.586l13.343 11.675Zm0-20.935-4.448-3.892V4.444h4.448Zm-18.9 3.886v7.413a1.859 1.859 0 0 0 1.853 1.853h7.411a1.86 1.86 0 0 0 1.856-1.853v-7.413a1.859 1.859 0 0 0-1.856-1.853h-7.414a1.858 1.858 0 0 0-1.853 1.853Zm2.224.37h6.671v6.671h-6.674Z" fill="#fff"/></svg>
                    <span>Meny</span>
                </div>
                <div className="mobile-menu__header">
                    <div className='mobile-menu__header-title'><strong>{currentPropertyAssociation?.name}</strong></div>
                    {section}
                </div>
            </button>
            { expanded && (
                <Fragment>
                    <div className="mobile-menu__content">
                        <MobileMenuSection title="Resurser" suffix="assets">
                            <div>
                                { assets && (
                                    <Fragment>
                                        <div className="mobile-menu__button-wrapper">
                                            <Link to="/assets/create/" className="mobile-menu__button">Lägg till ny resurs</Link>
                                        </div>
                                        { orderByStatus(assets).map(x => renderAssetLink(x, currentPath)) }
                                    </Fragment>
                                )}
                                { !assets && !isLoading && (
                                    <div className="mobile-menu__link">Ni har ännu inte lagt upp några resurser.</div>
                                )}
                            </div>
                        </MobileMenuSection>
                        <MobileMenuSection title="Bokningar" suffix="bookings">
                            <div>
                                { renderLink('/bookings/', 'Aktiva bokningar', currentPath) }
                                { renderLink('/bookings/archive/', 'Bokningshistorik', currentPath) }
                                { renderLink('/bookings/new/', 'Boka', currentPath) }
                            </div>
                        </MobileMenuSection>
                        <MobileMenuSection title='Åtkomst' suffix='members' available={isAdmin || !isGuest}>
                            <div>
                                { renderLink('/members/', 'Aktiva', currentPath) }
                                { renderLink('/members/pending', 'Förfrågningar', currentPath) }
                            </div>
                        </MobileMenuSection>
                        <MobileMenuSection title='Inställningar' suffix='settings' available={isAdmin || !isGuest}>
                            <div>
                                { renderLink('/settings/admins/', 'Admins', currentPath) }
                                { renderLink('/settings/', 'Allmänna inställningar', currentPath) }
                            </div>
                        </MobileMenuSection>
                        { hasMultiplePropertyAssociations && (
                            <div className="mobile-menu__section mobile-menu__section--noarrow">
                                <button className="mobile-menu__heading mobile-menu__heading--change" onClick={resetCurrentPropertyAssociation}>Välj engagemang</button>
                            </div>
                        )}
                    </div>
                    <div className="mobile-menu__footer">
                        {
                            isAuthenticated ? (
                                <Link to="/signout-oidc/" className="mobile-menu__footer-link">
                                    Logga ut
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#fff" d="M160 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L333.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H176c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L356.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H176c-26.5 0-48 21.6-48 48zM0 112v288c0 26.5 21.5 48 48 48h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h132c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H48C21.5 64 0 85.5 0 112z"/></svg>
                                </Link>
                            ): (
                                <a href="/" className="mobile-menu__footer-link" onClick={signIn}>
                                    Logga in
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="23" height="23"><path fill="#fff" d="M32 217.1c0-8.8 7.2-16 16-16h144v-93.9c0-7.1 8.6-10.7 13.6-5.7l141.6 143.1c6.3 6.3 6.3 16.4 0 22.7L205.6 410.4c-5 5-13.6 1.5-13.6-5.7v-93.9H48c-8.8 0-16-7.2-16-16v-77.7m-32 0v77.7c0 26.5 21.5 48 48 48h112v61.9c0 35.5 43 53.5 68.2 28.3l141.7-143c18.8-18.8 18.8-49.2 0-68L228.2 78.9c-25.1-25.1-68.2-7.3-68.2 28.3v61.9H48c-26.5 0-48 21.6-48 48zM512 400V112c0-26.5-21.5-48-48-48H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c26.5 0 48-21.5 48-48z"/></svg>
                                </a>
                            )
                        }
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default withWaitForPropertyAssociation(MobileNavigation)

function renderLink(path: string, text: string, currentPath: string): React.ReactNode {
    const isCurrentPath = currentPath === path;

    return <Link to={path} className={cx(['mobile-menu__link', { 'mobile-menu__link--current': isCurrentPath }])}>{text}</Link>
}

function renderAssetLink({ assetId, name, status }: AssetListItem, currentPath: string): React.ReactNode {
    const path = `/assets/edit/${assetId}/`;
    const isCurrentPath = currentPath === path;
    const classNames = cx([
        'mobile-menu__link', 
        { 'mobile-menu__link--current': isCurrentPath },
        { 'mobile-menu__link--draft': isDraft(status) },
        { 'mobile-menu__link--unpublished': isUnpublished(status) },
    ]);

    return <Link to={path} key={assetId} className={classNames}>{name}</Link>
}

function orderByStatus(assets: AssetListItem[]) {
    return assets.filter(x => x.status === AssetStatus.Published).concat(assets.filter(x => x.status !== AssetStatus.Published));
}
