import { FunctionComponent, ReactNode } from 'react';
import styles from './InfoBox.module.scss';
import cx from 'classnames';

type Props = {
    children: string | ReactNode | ReactNode[];
    className?: string;
    showIcon?: boolean;
};

const InfoBox: FunctionComponent<Props> = ({ children, className, showIcon = false }) => (
    <div className={cx(styles.infobox, className, {[styles.icon]: showIcon})}>{children}</div>
);

export default InfoBox;
