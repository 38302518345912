import { FunctionComponent, useContext, useEffect, useState } from 'react';
import ErrorMessage from '../../common/error/ErrorMessage';
import { ConfigContext } from '../configuration/ConfigContext';
import Button from '../forms/Button';
import { getInvitationsInPropertyAssociation } from '../invitation/invitationService';
import { GetInvitationsResponse } from '../invitation/models/GetInvitationsResponse';
import { InvitationInfoResponse } from '../invitation/models/InvitationInfoResponse';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import Tabs from '../shared/Tabs';
import { PropertyAssociationAccessLevel } from './adminEnums';
import { getPropertyAssociationAdmins, updatePropertyAssociationAdmins } from './adminService';
import ActiveInvitationList from './components/ActiveInvitationList';
import AdminList from './components/AdminList';
import InactiveInvitationList from './components/InactiveInvitationList';
import AddGuestAdminModal from './modals/AddGuestAdminModal';
import { Admin } from './models/Admin';
import { GuestInvitation } from './models/GuestInvitation';

const TAB_NAMES_LIST = 'list';
const TAB_NAMES_ACTIVE_INVITES = 'activeInvites';
const TAB_NAMES_INACTIVE_INVITES = 'inactiveInvites';

const TABS = [
    { title: 'Administratörer och gäster', value: TAB_NAMES_LIST },
    { title: 'Aktiva inbjudningar', value: TAB_NAMES_ACTIVE_INVITES },
    { title: 'Inaktiva inbjudningar', value: TAB_NAMES_INACTIVE_INVITES },
]

enum InvitationStatus {
    Accepted = 'Accepterad',
    Declined = 'Avböjd av användare',
    Revoked = 'Återtagen',
}

const Admins: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [activeTab, setActiveTab] = useState(TAB_NAMES_LIST);
    const [admins, setAdmins] = useState<Admin[]>();
    const [error, setError] = useState<string>();
    const [activeInvitations, setActiveInvitations] = useState<GuestInvitation[]>();
    const [inactiveInvitations, setInactiveInvitations] = useState<GuestInvitation[]>();
    const [showAddGuest, setShowAddGuest] = useState(false);

    const fetchAdmins = () => {
        getPropertyAssociationAdmins(apiBaseUrl, currentPropertyAssociationId,
            (data: Admin[]) => setAdmins(data ?? []),
            () => setError('Vi kunde inte hämta administratörer.')
        );
    }

    const fetchInvitations = () => {
        getInvitationsInPropertyAssociation(
            apiBaseUrl, 
            currentPropertyAssociationId, 
            setInvitations, 
            () => setError('Vi kunde inte hämta inbjudningar.')
        )
    }

    const setInvitations = (response: GetInvitationsResponse) => {
        const allInvitations = response.invitations.map(x => ({ email: x.email, id: x.applicationInvitationId, status: getStatus(x), validUntil: new Date(x.validUntil), accessLevel: getAccessLevel(x.accessLevel) }));
        const now = new Date();
        setActiveInvitations(allInvitations.filter(x => x.status === null && x.validUntil > now));
        setInactiveInvitations(allInvitations.filter(x => (x.status !== 'Accepterad' && x.validUntil <= now) || (x.status !== null && x.status !== InvitationStatus.Accepted )));
    }

    useEffect(() => {
        fetchAdmins();
        fetchInvitations();
    }, [])

    if(error) {
        return <ErrorMessage message={error} />
    }

    if(admins === undefined || activeInvitations === undefined || inactiveInvitations === undefined) {
        return null
    }

    const updateAdmins = () => {
        updatePropertyAssociationAdmins(apiBaseUrl, currentPropertyAssociationId, fetchAdmins, () => setError('Vi kunde inte uppdatera administratörer.'));
    }

    return (
        <PaperLayout heading="Hantera administratörer" tightenUp={true}>
            <div className="paper__infoCard">
                <h4>Ordinarie administratörer och inbjudna gäster</h4>
                <div>Ordinarie administratörer läses in från Bolagsverkets register. Listan behöver uppdateras om registret förändras för att rätt personer ska visas. Externa personer kan bli administratörer genom att bjudas in som gäster.</div>
                <div className='mt-3'><strong>Begränsad gäståtkomst</strong> innebär att användaren endast kan hantera bokningar. En användare med <strong>full gäståtkomst</strong> har samma behörigheter som styrelsen.</div>
                <div className='mt-3 d-flex justify-content-between'>
                    <Button onClick={updateAdmins} isPrimary={false} additionalClassName='btn-secondary' text="Uppdatera lista" />
                    <Button onClick={() => setShowAddGuest(true)} text="Bjud in gäst" />
                </div>
            </div>

            <Tabs activeTab={activeTab} onChange={setActiveTab} tabs={TABS} />

            {activeTab === TAB_NAMES_LIST &&  <AdminList admins={admins} onAdminUpdated={fetchAdmins} />}
            {activeTab === TAB_NAMES_ACTIVE_INVITES && <ActiveInvitationList invitations={activeInvitations} reload={fetchInvitations} />}
            {activeTab === TAB_NAMES_INACTIVE_INVITES && <InactiveInvitationList invitations={inactiveInvitations} />}

            {showAddGuest && <AddGuestAdminModal reload={fetchInvitations} onClose={() => setShowAddGuest(false)} isVisible={showAddGuest} />}
        </PaperLayout>
    )
}

function getAccessLevel(accessLevel: PropertyAssociationAccessLevel | undefined | null) {
    switch(accessLevel) {
        case PropertyAssociationAccessLevel.Adjunct: return 'Adjungerad';
        case PropertyAssociationAccessLevel.Guest: return 'Gäst';
        default: return 'Gäst';
    }
}

function getStatus(invitation: InvitationInfoResponse) {
    if(invitation.invitationAccepted === true) {
        return InvitationStatus.Accepted;
    }

    if(invitation.invitationAccepted === false && invitation.userName !== null) {
        return InvitationStatus.Declined;
    }

    if(invitation.invitationAccepted === false) {
        return InvitationStatus.Revoked;
    }

    return null;
}

export default withPropertyAssociation(Admins, false)