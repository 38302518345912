import { FunctionComponent } from 'react';
import PaperLayout from '../layout/PaperLayout';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import MemberList from './components/MemberList';
import { MembersSortDirection, MembersSortOrder } from './memberConstants';
import { getMembers } from './memberService';

const Members: FunctionComponent = () => (
    <PaperLayout heading="Åtkomst" tightenUp={true} mainClass="pt-4">
        <div className="mb-4">
            Här kan du kontrollera vilka användare som har åtkomst till gemensamma resurser och anslagstavlan i appen Mitt Boende för din förening. I listan framgår vilka som är folkbokförda och vilka som inte är folkbokförda och har åtkomst. Under fliken förfrågningar kan du godkänna förfrågningar från de som inte är folkbokförda, som vill komma åt tjänster i din förening. 
            De boende som listas här har laddat ned Mitt Boende-appen och accepterat användarvillkoren.
        </div>
        <MemberList
            isPending={false}
            defaultSortOrder={MembersSortOrder.FirstName}
            defaultSortDirection={MembersSortDirection.Descending}
            fetchFunction={getMembers} />
    </PaperLayout>
)

export default withPropertyAssociation(Members, false)