import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { TagSuggestion } from 'react-tag-autocomplete';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import Button from '../../forms/Button';
import TagsField from '../../forms/TagsField';
import TextField from '../../forms/TextField';
import { requiredValidator } from '../../forms/validators';
import { getFilteredMembers } from '../../members/memberService';
import FormModal from '../../modal/FormModal';
import OverlayConfirmModal from '../../modal/OverlayConfirmModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { deleteConversation, updateConversationGroup } from '../conversationService';
import { ConversationModel } from '../models/ConversationModel';

type Props = {
    conversation: ConversationModel;
    onClose: () => void;
    onGroupUpdated: (conversationId: string) => void;
}

type FormData = {
    name: string;
    participants: string[];
    propertyAssociationAsParticipant: boolean;
}

const validationSchema = Yup.object().shape({
    name: requiredValidator,
    participants: Yup.array().min(1, 'Du måste lägga till minst en deltagare')
})

const EditGroupModal: FunctionComponent<Props> = ({ conversation, onClose, onGroupUpdated }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [initialUsers, setInitialUsers] = useState<TagSuggestion[]>();
    const [initialValues, setInitialValues] = useState<FormData>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [suggestions, setSuggestions] = useState<TagSuggestion[]>();

    useEffect(() => {
        getFilteredMembers(
            apiBaseUrl,
            currentPropertyAssociationId,
            (response) => setSuggestions(response.members.map(mapToTagSuggestion)),
            () => setError('Kunde inte hämta användare')
        );
    }, [])

    useEffect(() => {
        if(!conversation || !suggestions) {
            return;
        }

        setInitialValues({
            name: conversation.name ?? '',
            participants: conversation.participants.map(x => x.user.userId),
            propertyAssociationAsParticipant: conversation.propertyAssociationAsParticipant,
        })

        setInitialUsers(
            conversation.participants.map(x => ({ value: x.user.userId, label: suggestions.find(y => y.value === x.user.userId)?.label ?? 'Okänd' }))
        );
    }, [conversation, suggestions]);

    const onSubmit = ({ name, participants, propertyAssociationAsParticipant }: FormData) => {
        setIsSubmitting(true);
        updateConversationGroup(apiBaseUrl, currentPropertyAssociationId, conversation.conversationId, name, propertyAssociationAsParticipant, participants, onSubmitted, onError);
    }

    const onSubmitted = () => {
        setIsSubmitting(false);
        onGroupUpdated(conversation.conversationId);
    }

    const onError = () => {
        setIsSubmitting(false);
        setError('Kunde inte spara gruppen');
    }

    const onDeleted = () => {
        onGroupUpdated(conversation.conversationId);
        onClose();
    }

    const deleteGroup = () => {
        deleteConversation(apiBaseUrl, currentPropertyAssociationId, conversation.conversationId, onDeleted, onError);
    }

    if(!initialValues || !suggestions) {
        return null;
    }

    return (
        <FormModal initialValues={initialValues} validationSchema={validationSchema} onClose={onClose} title="Administrera chattgrupp" submitText="Spara" scrollable={false} isSubmitting={isSubmitting} onSubmit={onSubmit} visible={true}>
            {({ values, errors, touched }) => (
                <>
                    <TextField name="name" label="Namn för gruppen:" disabled={isSubmitting} values={values} errors={errors} touched={touched} />

                    <TagsField name="participants" label="Deltagare:" placeholder="Lägg till deltagare" suggestions={suggestions} initialValues={initialUsers} />

                    <div className="mt-4">
                        <BooleanCheckBox name="propertyAssociationAsParticipant" label="Lägg till styrelsen som deltagare (kryssa i denna för att kunna skicka meddelanden till gruppen)" disabled={isSubmitting} />
                    </div>

                    <div className="mt-5 alert alert-danger">
                        <Button text="Ta bort chattgrupp" onClick={() => setShowDeleteModal(true)} />
                    </div>

                    <OverlayConfirmModal heading="Ta bort gruppen?" text="Är du säker på att du vill ta bort hela gruppen?" okButtonText="Ta bort gruppen" cancelButtonText="Avbryt" visible={showDeleteModal} onSubmit={deleteGroup} onCancel={() => setShowDeleteModal(false)} />

                    {error && <div className="alert alert-danger">{error}</div>}
                </>
            )}
        </FormModal>
    )
}

const mapToTagSuggestion = (x: { userId: string; fullName: string; }): TagSuggestion => ({ value: x.userId, label: x.fullName });

export default EditGroupModal