import { FunctionComponent, useEffect } from 'react'
import { scrollElementTo } from '../../../common/utils/domUtils'
import Button from '../../forms/Button'
import PaperLayout from '../../layout/PaperLayout'
import Accordion from '../../shared/Accordion'
import StepGuide, { Step } from '../../shared/StepGuide'

type Props = {
    onGoBack: () => void
}

const GetStartedWithSwish: FunctionComponent<Props> = ({ onGoBack }) => {
    useEffect(() => {
        scrollElementTo('content', 0);
    }, []);

    return (
        <PaperLayout
            heading="Kom igång med Swish"
            preamble="Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius. Adipiscing pretium in quam vitae. Ac eleifend tincidunt sapien pellentesque sem dui."
            mainClass="pb-5 pt-1"
        >
            <p>Notera att den här kom-igång-guiden utgår från Swedbanks steg.</p>

            <StepGuide>
                <Step>
                    <Accordion heading="Registrera uppgifter" startExpanded={true}>
                        <p>Här behöver du ange uppgifter för att ansluta företaget till tjänsten.</p>
                    </Accordion>
                </Step>
                <Step>
                    <Accordion heading="Teknisk anslutning">
                        <p>För att tjänsten ska sättas i bruk behöver du registrera Riksbyggen som teknisk leverantör. Det här innebär att du medger att uppkopplingen till Swish sker med certifikat utfärdat till en teknisk leverantör. Den här uppkopplningen gör att det kommer kunna göras en anslutning för ett swish-nummer.</p>
                        <p><strong>Hur du gör:</strong> Lorem ipsum dolor sit amet consectetur. Cras hac tincidunt sed venenatis in quis. Adipiscing egestas ut ullamcorper eu fermentum. Ut id in posuere enim urna vel sed pellentesque libero. Id id turpis cursus dolor enim dignissim et non nulla.</p>
                    </Accordion>
                </Step>
                <Step>
                    <Accordion heading="Säljställe">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur commodo faucibus mattis. Donec at condimentum mauris. Etiam vehicula consectetur tempus. Phasellus sit amet elementum quam. Etiam quis augue rutrum, varius ex non, auctor enim. Pellentesque id pellentesque lacus. Sed pulvinar non lacus in auctor.</p>
                        <p>Morbi malesuada ut nisi eu imperdiet. Sed ut commodo nisi, at imperdiet orci. Integer ultricies, quam et ultrices porttitor, sem augue hendrerit tellus, eu ornare enim magna quis tortor. Cras in tortor ac massa ultricies rutrum. Pellentesque sed consectetur urna, quis fringilla enim. Etiam eleifend elit in varius auctor. Ut pellentesque nibh non nibh sodales, ac interdum nisi imperdiet. Mauris fringilla, mauris sed faucibus elementum, magna mi condimentum lacus, in ultrices orci lacus eu diam.</p>
                    </Accordion>
                </Step>
                <Step>
                    <Accordion heading="Försäljningsinformation">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur commodo faucibus mattis. Donec at condimentum mauris. Etiam vehicula consectetur tempus. Phasellus sit amet elementum quam. Etiam quis augue rutrum, varius ex non, auctor enim. Pellentesque id pellentesque lacus. Sed pulvinar non lacus in auctor.</p>
                        <p>Morbi malesuada ut nisi eu imperdiet. Sed ut commodo nisi, at imperdiet orci. Integer ultricies, quam et ultrices porttitor, sem augue hendrerit tellus, eu ornare enim magna quis tortor. Cras in tortor ac massa ultricies rutrum. Pellentesque sed consectetur urna, quis fringilla enim. Etiam eleifend elit in varius auctor. Ut pellentesque nibh non nibh sodales, ac interdum nisi imperdiet. Mauris fringilla, mauris sed faucibus elementum, magna mi condimentum lacus, in ultrices orci lacus eu diam.</p>
                    </Accordion>
                </Step>
                <Step>
                    <Accordion heading="Kontrollera">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur commodo faucibus mattis. Donec at condimentum mauris. Etiam vehicula consectetur tempus. Phasellus sit amet elementum quam. Etiam quis augue rutrum, varius ex non, auctor enim. Pellentesque id pellentesque lacus. Sed pulvinar non lacus in auctor.</p>
                        <p>Morbi malesuada ut nisi eu imperdiet. Sed ut commodo nisi, at imperdiet orci. Integer ultricies, quam et ultrices porttitor, sem augue hendrerit tellus, eu ornare enim magna quis tortor. Cras in tortor ac massa ultricies rutrum. Pellentesque sed consectetur urna, quis fringilla enim. Etiam eleifend elit in varius auctor. Ut pellentesque nibh non nibh sodales, ac interdum nisi imperdiet. Mauris fringilla, mauris sed faucibus elementum, magna mi condimentum lacus, in ultrices orci lacus eu diam.</p>
                    </Accordion>
                </Step>
                <Step>
                    <Accordion heading="Bekräftelse">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur commodo faucibus mattis. Donec at condimentum mauris. Etiam vehicula consectetur tempus. Phasellus sit amet elementum quam. Etiam quis augue rutrum, varius ex non, auctor enim. Pellentesque id pellentesque lacus. Sed pulvinar non lacus in auctor.</p>
                        <p>Morbi malesuada ut nisi eu imperdiet. Sed ut commodo nisi, at imperdiet orci. Integer ultricies, quam et ultrices porttitor, sem augue hendrerit tellus, eu ornare enim magna quis tortor. Cras in tortor ac massa ultricies rutrum. Pellentesque sed consectetur urna, quis fringilla enim. Etiam eleifend elit in varius auctor. Ut pellentesque nibh non nibh sodales, ac interdum nisi imperdiet. Mauris fringilla, mauris sed faucibus elementum, magna mi condimentum lacus, in ultrices orci lacus eu diam.</p>
                    </Accordion>
                </Step>
            </StepGuide>

            <Button type="button" text="Gå tillbaka" isPrimary={false} additionalClassName="btn-secondary btn-prev" onClick={onGoBack} />
        </PaperLayout>
    )
}

export default GetStartedWithSwish
