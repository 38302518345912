import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ConfigContext } from '../configuration/ConfigContext';
import Button from '../forms/Button';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import NoMessages from './components/NoPinboardMessages';
import PinboardMessageItem from './components/PinboardMessageItem';
import PinboardMessageList from './components/PinboardMessageList';
import { GetPinboardMessagesResponse } from './models/GetPinboardMessagesResponse';
import { getPinboardMessages } from './pinboardMessageService';

const PinboardMessages: FunctionComponent = () => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState<GetPinboardMessagesResponse | undefined>();

    const loadMessages = () => {
        setIsLoading(true);
        getPinboardMessages(
            apiBaseUrl,
            currentPropertyAssociationId,
            currentArea?.propertyAssociationAreaId,
            (data) => {
                setIsLoading(false);
                setResponse(data);
            },
            () => {
                setIsLoading(false);
            }
        );
    };
    
    useEffect(() => {
        if (apiBaseUrl && currentPropertyAssociationId) {
            loadMessages();
        }
    }, [apiBaseUrl, currentPropertyAssociationId]);

    const pinboardMessages = response?.pinboardMessages || [];
    const canCreateNew = response?.canCreateNew || false;

    return (
        <Fragment>
            <PaperLayout 
                heading="Styrelsen informerar" 
                tightenUp={true} 
                mainClass="pt-4"
                preamble={(
                    <p>
                        Här visas meddelanden som publicerats under Styrelsen informerar i Mitt Riksbyggen.
                    </p>
                )}
            >
                {canCreateNew && (
                    <div className='d-flex mb-4 pb-2'>
                        <Button text='Skapa inlägg' additionalClassName='ms-auto' isPrimary onClick={() => history.push('/adverts/information/create/')} />
                    </div>
                )}
                {isLoading && (
                    <div className="paper__message  paper__message--large">
                        <div className="paper__subheading text-center">Hämtar meddelanden...</div>
                    </div>
                )}
                {!isLoading && pinboardMessages.length <= 0 && <NoMessages />}
                {!isLoading && pinboardMessages.length > 0 && (
                    <PinboardMessageList>
                        {pinboardMessages.map((item) => <PinboardMessageItem key={item.pinboardMessageId} {...item} />)}
                    </PinboardMessageList>
                )}
            </PaperLayout>
        </Fragment>
    );
};

export default withPropertyAssociation(PinboardMessages, false);
