import cx from 'classnames';
import { FunctionComponent, ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { withWaitForPropertyAssociation } from '../../propertyAssociation/withWaitForPropertyAssociation';
import SubNavigation from '../SubNavigation';

const AdvertsMenu: FunctionComponent = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [expanded, setExpanded] = useState(true);

    return (
        <SubNavigation heading="Anslagstavlan" isExpanded={expanded} onExpand={setExpanded}>
            <div className="submenu__content">
                <div className="submenu__links">
                    { renderLink('/adverts/user-adverts/', 'Dela, hyr, sälj', currentPath) }
                    { renderLink('/adverts/information/', 'Styrelsen informerar', currentPath) }
                </div>
            </div>
        </SubNavigation>
    );
}

export default withWaitForPropertyAssociation(AdvertsMenu, false)

function renderLink(path: string, text: string, currentPath: string): ReactNode {
    const isCurrentPath = !!currentPath?.startsWith(path);
    return <Link to={path} className={cx(['submenu__link', { 'submenu__link--current': isCurrentPath }])}>{text}</Link>
}
