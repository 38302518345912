import { FunctionComponent, ReactNode } from 'react';
import styles from './StepGuide.module.scss';

type Props = {
    children: ReactNode | ReactNode[];
}

type StepProps = {
    children: string | ReactNode | ReactNode[];
}

const StepGuide: FunctionComponent<Props> = ({ children }) => {
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

export const Step: FunctionComponent<StepProps> = ({ children }) => {
    return (
        <div className={styles.step}>
            {children}
        </div>
    )
}

export default StepGuide