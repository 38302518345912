import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAsyncError } from '../../common/error/useAsyncError';
import { ConfigContext } from '../configuration/ConfigContext';
import { Configuration } from '../configuration/Configuration';
import { ToastMessageType } from '../toaster/models/ToastMessageType';
import { ToastContext } from '../toaster/ToastContext';
import { GetPropertyAssociationAreasResponse } from './models/GetPropertyAssociationAreasResponse';
import { PropertyAssociationArea } from './models/PropertyAssociationArea';
import { PropertyAssociationInfo } from './models/PropertyAssociationInfo';
import { getPropertyAssociationAreas } from './propertyAssociationAreaService';
import { getPropertyAssociation, getPropertyAssociations } from './propertyAssociationService';
import { getpropertyAssociationId, storepropertyAssociationId } from './propertyAssociationStorage';

type Context = {
    allAreas: PropertyAssociationArea[] | undefined;
    allPropertyAssociations: PropertyAssociationInfo[] | undefined;
    currentArea: PropertyAssociationArea | undefined;
    currentPropertyAssociation: PropertyAssociationInfo | undefined;
    currentPropertyAssociationId: string;
    isAdmin: boolean;
    isGuest: boolean;
    loadPropertyAssociations: () => void;
    requireAreaSelection: boolean;
    setCurrentArea: (area: PropertyAssociationArea | undefined) => void;
    setCurrentPropertyAssociation: (propertyAssociation: PropertyAssociationInfo | undefined) => void;
    setRequireAreaSelection: (requireAreaSelection: boolean) => void;
};

type Props = {
    config: Configuration;
};

export const PropertyAssociationContext = createContext<Context>({
    allAreas: undefined,
    allPropertyAssociations: undefined,
    currentArea: undefined,
    currentPropertyAssociation: undefined,
    currentPropertyAssociationId: '',
    isAdmin: false,
    isGuest: true,
    loadPropertyAssociations: () => {
        /* undefined */
    },
    requireAreaSelection: false,
    setCurrentArea: () => {
        /* undefined */
    },
    setCurrentPropertyAssociation: () => {
        /* undefined */
    },
    setRequireAreaSelection: () => {
        /* undefined */
    },
});

export const PropertyAssociationProvider: FunctionComponent<Props> = ({ children, config }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast, clearToasts } = useContext(ToastContext);
    const [currentArea, setCurrentArea] = useState<PropertyAssociationArea>();
    const [currentPropertyAssociation, setCurrentPropertyAssociation] = useState<PropertyAssociationInfo>();
    const [allAreas, setAllAreas] = useState<PropertyAssociationArea[]>();
    const [allPropertyAssociations, setAllPropertyAssociations] = useState<PropertyAssociationInfo[]>();
    const [requireAreaSelection, setRequireAreaSelection] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const { search } = useLocation();
    const history = useHistory();
    const throwAsyncError = useAsyncError();

    const setCurrentPropertyAssociationWithChecks = (propertyAssociation: PropertyAssociationInfo | undefined) => {
        if(!propertyAssociation) {
            setCurrentPropertyAssociation(propertyAssociation);
            clearToasts();
            return;
        }

        getPropertyAssociationAreas(
            apiBaseUrl,
            propertyAssociation.propertyAssociationId,
            ({ propertyAssociationAreas: areas }: GetPropertyAssociationAreasResponse) => {
                if (areas.length === 1) {
                    setCurrentArea(areas[0]);
                    setRequireAreaSelection(false);
                }
                else if (areas.length > 1) {
                    setRequireAreaSelection(true);
                }
                else {
                    setRequireAreaSelection(false);
                }

                setAllAreas(areas);
                setCurrentPropertyAssociation(propertyAssociation);
                
                if(propertyAssociation && !propertyAssociation.email) {
                    addToast('E-postadress saknas', <p>Ni har inte angivit en e-postaddress, för notifieringar, till styrelsen. Du kan göra detta under <Link to="/settings/">Inställningar</Link>.</p>, ToastMessageType.Static);
                }
            },
            () => throwAsyncError('Kunde inte ladda områden!')
        );
    }

    const loadPropertyAssociations = (searchParameters: any) => () => {
        getPropertyAssociations(
            config.apiBaseUrl,
            ({isAdmin, propertyAssociations}) => {
                setIsAdmin(isAdmin);

                setAllPropertyAssociations(propertyAssociations ?? []);

                if(isAdmin && hasPropertyAssociationId(searchParameters)) {
                    // NOTE: Prioritize property association id from query string if user is admin
                    const propertyAssociationQueryId  = getPropertyAssociationQueryId(searchParameters);
                    if(propertyAssociationQueryId) {
                        getPropertyAssociation(
                            config.apiBaseUrl,
                            propertyAssociationQueryId,
                            setCurrentPropertyAssociationWithChecks,
                            () => { /* Suppress any error */ }
                        );
                    }
                    return;
                }
                if (propertyAssociations && propertyAssociations.length === 1) {
                    const propertyAssociation = propertyAssociations[0];
                    if(isAdmin || propertyAssociation.isActive) {
                        setCurrentPropertyAssociationWithChecks(propertyAssociation);
                        return;
                    }
                    
                    if (propertyAssociation.isTrialSubscription) {
                        history.push('/trial-has-ended');
                        return;
                    }

                    history.push('/no-subscription');
                    return;
                }
                if(setCurrentPropertyAssociationFromQueryString(propertyAssociations, setAndPersistCurrentPropertyAssociation, searchParameters, isAdmin)) {
                    return;
                }
                if(setCurrentPropertyAssociationFromStorage(propertyAssociations, setAndPersistCurrentPropertyAssociation, isAdmin)) {
                    return;
                }
            },
            () => throwAsyncError('Kunde inte ladda styrelse!')
        );
    };

    const setAndPersistCurrentPropertyAssociation = (propertyAssociation: PropertyAssociationInfo | undefined) => {
        setCurrentPropertyAssociationWithChecks(propertyAssociation);
        if(propertyAssociation) {
            storepropertyAssociationId(propertyAssociation.propertyAssociationId);
        }
    }

    const [value, setValue] = useState<Context>({
        allAreas: allAreas,
        allPropertyAssociations: allPropertyAssociations,
        currentArea: currentArea,
        currentPropertyAssociation: currentPropertyAssociation,
        currentPropertyAssociationId: currentPropertyAssociation?.propertyAssociationId || '',
        isAdmin: isAdmin,
        isGuest: true,
        loadPropertyAssociations: loadPropertyAssociations(search),
        requireAreaSelection: requireAreaSelection,
        setCurrentArea: setCurrentArea,
        setCurrentPropertyAssociation: setAndPersistCurrentPropertyAssociation,
        setRequireAreaSelection: setRequireAreaSelection,
    });

    useEffect(() => {
        setValue({
            allAreas: allAreas,
            allPropertyAssociations: allPropertyAssociations,
            currentArea: currentArea,
            currentPropertyAssociation: currentPropertyAssociation,
            currentPropertyAssociationId: currentPropertyAssociation?.propertyAssociationId || '',
            isAdmin: isAdmin,
            isGuest: currentPropertyAssociation?.isGuest ?? true,
            loadPropertyAssociations: loadPropertyAssociations(search),
            requireAreaSelection: requireAreaSelection,
            setCurrentArea: setCurrentArea,
            setCurrentPropertyAssociation: setAndPersistCurrentPropertyAssociation,
            setRequireAreaSelection: setRequireAreaSelection,
        });
    }, [allAreas, allPropertyAssociations, currentArea, currentPropertyAssociation, requireAreaSelection, search]);

    return <PropertyAssociationContext.Provider value={value}>{children}</PropertyAssociationContext.Provider>;
};

const setCurrentPropertyAssociationFromStorage = (propertyAssociations: PropertyAssociationInfo[] | undefined, setCurrentPropertyAssociation: (propertyAssociation: PropertyAssociationInfo) => void, isAdmin: boolean): boolean => {
    const propertyAssociationId = getpropertyAssociationId();
    if (!propertyAssociationId) {
        return false;
    }

    const propertyAssociation = propertyAssociations?.find(x => x.propertyAssociationId === propertyAssociationId);
    if (propertyAssociation && (isAdmin || propertyAssociation.isActive)) {
        setCurrentPropertyAssociation(propertyAssociation);
        return true;
    }

    return false;
};

const setCurrentPropertyAssociationFromQueryString = (
propertyAssociations: PropertyAssociationInfo[] | undefined, setCurrentPropertyAssociation: (propertyAssociation: PropertyAssociationInfo) => void, searchParameters: any, isAdmin: boolean,
): boolean => {
    const propertyAssociationQueryId = getPropertyAssociationQueryId(searchParameters);
    if (!propertyAssociationQueryId) {
        return false;
    }

    const propertyAssociation = propertyAssociations?.find(x => x.propertyAssociationId === propertyAssociationQueryId);
    if (propertyAssociation && (isAdmin || propertyAssociation.isActive)) {
        setCurrentPropertyAssociation(propertyAssociation);
        return true;
    }

    return false;
};

const hasPropertyAssociationId = (searchParameters: any): boolean => {
    return new URLSearchParams(searchParameters).has('propertyAssociationId');
};

const getPropertyAssociationQueryId = (searchParameters: any) => {
    return new URLSearchParams(searchParameters).get('propertyAssociationId');
};
