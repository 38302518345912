import { Form, Formik } from 'formik'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useAsyncError } from '../../common/error/useAsyncError'
import { ConfigContext } from '../configuration/ConfigContext'
import Button from '../forms/Button'
import MaskedTextField from '../forms/MaskedTextField'
import RadioButton from '../forms/RadioButton'
import RadioButtonGroup from '../forms/RadioButtonGroup'
import { swishMask } from '../forms/masks'
import PaperLayout from '../layout/PaperLayout'
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext'
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation'
import GetStartedWithSwish from './components/GetStartedWithSwish'
import SwishInfoBox from './components/SwishInfoBox'
import SwishSupplierInformation from './components/SwishSupplierInformation'
import { getPropertyAssociationSettings, updatePropertyAssociationSettings } from './settingsService'
import { ToastContext } from '../toaster/ToastContext'

type FormData = {
    hasSwish: string;
    hasChosenRiksbyggen: string;
    swishNumber: string;
}

const swishRegExp = /^(\d{3} ?\d{3} ?\d{2} ?\d{2})$/;

const validationSchema = Yup.object().shape({
    swishNumber: Yup.string().required('Swish-nummer måste anges').matches(swishRegExp, 'Ogiltigt Swish-nummer (ska vara "123" följt av sju siffror)'),
})

const SwishSettings: FunctionComponent = () => {
    const throwAsync = useAsyncError();
    const { apiBaseUrl } = useContext(ConfigContext);
    const { addToast } = useContext(ToastContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [error, setError] = useState<string | undefined>();
    const [initialValues, setInitialValues] = useState<FormData>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showGetStarted, setShowGetStarted] = useState(false);
    const [showSwishSupplierInformation, setShowSwishSupplierInformation] = useState(false);

    const fetchSettings = () => {
        getPropertyAssociationSettings(
            apiBaseUrl, 
            currentPropertyAssociationId,
            ({ provideSwishPayment, swishNumber }) => {
                if(provideSwishPayment && swishNumber) {
                    setInitialValues({
                        hasSwish: 'yes',
                        hasChosenRiksbyggen: 'yes',
                        swishNumber: swishNumber,
                    })
                }
                else {
                    setInitialValues({
                        hasSwish: '',
                        hasChosenRiksbyggen: '',
                        swishNumber: '123 ',
                    })
                }
            },
            () => throwAsync(new Error('Det gick inte att hämta inställningarna'))
        )
    }

    useEffect(() => {
        fetchSettings()
    }, []);

    if(!initialValues) {
        return null;
    }

    if(showGetStarted) {
        return <GetStartedWithSwish onGoBack={() => setShowGetStarted(false)} />;
    }

    if(showSwishSupplierInformation) {
        return <SwishSupplierInformation onGoBack={() => setShowSwishSupplierInformation(false)} onGetStarted={() => setShowGetStarted(true)} />;
    }

    const handleSubmit = (values) => {
        const settings = {
            provideSwishPayment: true,
            swishNumber: values.swishNumber,
        }

        setIsSubmitting(true);
        updatePropertyAssociationSettings(apiBaseUrl, currentPropertyAssociationId, settings, onSubmitted, () => setError('Det gick inte att uppdatera inställningarna'));
    }

    const onSubmitted = () => {
        addToast('Swish-inställningar sparade');
        setIsSubmitting(false);
        fetchSettings();
    }

    const disabledRadioButtons = isSubmitting || initialValues.hasSwish === 'yes';

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ errors, isValid, touched, values }) => (
                <Form>
                    <PaperLayout
                        heading="Swish"
                        preamble="Lorem ipsum dolor sit amet consectetur. Quis augue nunc massa vel adipiscing nulla id scelerisque integer. Blandit nibh turpis id venenatis aliquet dolor eget feugiat. At tellus nisl risus in id vel. Gravida leo pharetra eget id magna nec dictum sit. Lorem ipsum dolor sit amet consectetur. Quis augue nunc massa vel adipiscing nulla id scelerisque integer."
                        mainClass="pb-5 pt-1"
                        tightenUp={true}
                    >
                        <div className="mb-4">
                            <h2>Har du Swish Handel idag?</h2>
                            <RadioButtonGroup name="hasSwish" className="d-flex gap-5" errors={errors} touched={touched}>
                                <RadioButton name="hasSwish" value="yes" label="Ja" readonly={disabledRadioButtons} />
                                <RadioButton name="hasSwish" value="no" label="Nej" readonly={disabledRadioButtons} />
                            </RadioButtonGroup>
                        </div>

                        { values.hasSwish === 'yes' && (
                            <>
                                <div className="mb-4">
                                    <h2>Har du valt Riksbyggen som teknisk leverantör?</h2>
                                    <RadioButtonGroup name="hasChosenRiksbyggen" className="d-flex gap-5" errors={errors} touched={touched}>
                                        <RadioButton name="hasChosenRiksbyggen" value="yes" label="Ja" readonly={disabledRadioButtons} />
                                        <RadioButton name="hasChosenRiksbyggen" value="no" label="Nej" readonly={disabledRadioButtons} />
                                    </RadioButtonGroup>
                                </div>

                                { values.hasChosenRiksbyggen === 'yes' && (
                                    <>
                                        <div className="mb-4">
                                            <h2>Skriv in ditt Swish-nummer</h2>
                                            <p className="mb-3">Skriv in ditt Swish-nummer (123-nummer) som du har fått av banken när du angav Riksbyggen som din tekniska leverantör</p>
                                            <MaskedTextField name="swishNumber" label="Swish-nummer" placeholder="123 XXX XX XX" mask={swishMask} extraClass="swish-field__input" errors={errors} touched={touched} values={values} />
                                        </div>

                                        <Button type="submit" text="Spara Swish-nummer" disabled={!isValid || isSubmitting} />
                                    </>
                                )}

                                { values.hasChosenRiksbyggen === 'no' && (
                                    <>
                                        <h2>Kom igång med Swish Handel</h2>
                                        <p>Innan du kan använda Swish Handel behöver du ange Riksbyggen som din tekniska leverantör och få ett swish-nummer. Om du inte vet hur du gör det här kan du nedan kan du gå vidare till vår kom-igång-guide.</p>
                                        
                                        <Button type="button" text="Kom igång" onClick={() => setShowSwishSupplierInformation(true)} />

                                        <SwishInfoBox />
                                    </>
                                )}
                            </>
                        )}

                        { values.hasSwish === 'no' && (
                            <>
                                <h2>Kom igång med Swish Handel</h2>
                                <p>Innan du kan använda Swish Handel behöver du ange Riksbyggen som din tekniska leverantör och få ett swish-nummer. Om du inte vet hur du gör det här kan du nedan kan du gå vidare till vår kom-igång-guide.</p>
                                
                                <Button type="button" text="Kom igång" onClick={() => setShowGetStarted(true)} />
                                
                                <SwishInfoBox />
                            </>
                        )}
                        
                        {error && <div className="alert alert-danger mt-5">{error}</div>}
                    </PaperLayout>
                </Form>
            )}
        </Formik>
    )
}

export default withPropertyAssociation(SwishSettings)