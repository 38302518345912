import { Form, Formik, FormikProps } from 'formik';
import { FunctionComponent, useCallback, useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import MaxTextField from '../../forms/MaxTextField';
import RadioButton from '../../forms/RadioButton';
import RadioButtonGroup from '../../forms/RadioButtonGroup';
import TextArea from '../../forms/TextArea';
import { requiredValidator } from '../../forms/validators';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import { PinboardMessageFormModel } from '../models/PinboardMessageFormModel';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';

type Props = {
    isNew?: boolean;
    messageForm: PinboardMessageFormModel;
    submitBtnText?: string;
    user?: { imagePath: string; firstName: string; lastName: string };
    onValidSubmit: (values: PinboardMessageFormModel) => void;
    onCancel: () => void;
}

const validationSchema = Yup.object().shape({
    title: requiredValidator.max(30, 'Ämnet får vara max 30 tecken.'),
    text: requiredValidator.max(10000, 'Meddelandet får vara max 10 000 tecken.'),
    canUserAnswer: Yup.string()
        .nullable()
        .matches(/ja|nej/, 'Ogiltigt värde')
        .required('Välj om meddelandet skall gå att svara på'),
    target: Yup.string().nullable().required('Välj mottagare'),
})

const PinboardMessageForm: FunctionComponent<Props> = ({ isNew = false, messageForm, onCancel, onValidSubmit, submitBtnText }) => {
    const formikRef = useRef<FormikProps<any>>();
    const { currentArea } = useContext(PropertyAssociationContext);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const onTrySubmit = useCallback(() => {
        if (!validationSchema.isValidSync(formikRef.current?.values)) {
            setShowErrorModal(true);
        }
    }, []);

    return <>
        <Formik initialValues={messageForm} innerRef={formikRef as any} enableReinitialize={true} validationSchema={validationSchema} validateOnMount={true} onSubmit={onValidSubmit}>
            {({ values, errors, touched }) => {
                const formProps = { values, errors, touched };

                return (
                    <Form>
                        <div className='col-12 col-md-8 px-4 ms-2'>
                            <div className='mb-5 position-relative'>
                                <MaxTextField maxLength={30} label='Rubrik' name='title' {...formProps} />
                            </div>
                            <TextArea label='Meddelande' name='text' {...formProps}></TextArea>
                            {isNew && currentArea?.propertyAssociationAreaId && (
                                <div className='my-4'>
                                    <p className='my-0'>Välj mottagare</p>
                                    <RadioButtonGroup {...formProps} name='target'>
                                        <div className='my-3'>
                                            <RadioButton name='target' label='Alla boende (i alla områden)' value='all' />
                                        </div>
                                        <div className='mt-3'>
                                            <RadioButton name='target' label={`Endast boende i ${currentArea.name}`} value={currentArea.propertyAssociationAreaId} />
                                        </div>
                                    </RadioButtonGroup>
                                </div>
                            )}
                            <div className='mb-5'>
                                <p className='my-0'>Ska meddelandet gå att svara på?</p>
                                <p className='meta mb-4 mt-2'>Ska de boende kunna svara på meddelandet via appen.</p>
                                <RadioButtonGroup {...formProps} name='canUserAnswer'>
                                    <div className='my-3'>
                                        <RadioButton name='canUserAnswer' label='Nej' value='nej' />
                                    </div>
                                    <div className='mt-3'>
                                        <RadioButton name='canUserAnswer' label='Ja' value='ja' />
                                    </div>
                                </RadioButtonGroup>
                            </div>
                        </div>
                        <div className='paper__buttons'>
                            <div className='paper__secondary-buttons'>
                                <button type='button' className='btn btn-lg btn-secondary' onClick={onCancel}>
                                    Avbryt
                                </button>
                            </div>
                            <div className='paper__primary-buttons'>
                                <button type='submit' className='btn btn-lg btn-success' onClick={onTrySubmit}>
                                    {submitBtnText || 'Spara'}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
        <OverlayAlertModal
            heading='Det saknas information'
            text={`Det verkar som att viss obligatorisk information för meddelandet saknas. När du fyllt i allt kommer du kunna ${submitBtnText?.toLocaleLowerCase() || 'spara'} det.`}
            visible={showErrorModal}
            onClick={() => setShowErrorModal(false)}
        />
    </>;
}

export default PinboardMessageForm