import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { TagSuggestion } from 'react-tag-autocomplete';
import * as Yup from 'yup';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import TagsField from '../../forms/TagsField';
import TextField from '../../forms/TextField';
import { requiredValidator } from '../../forms/validators';
import { getFilteredMembers } from '../../members/memberService';
import FormModal from '../../modal/FormModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { createConversationGroup } from '../conversationService';
import { CreateConversationResponse } from '../models/CreateConversationResponse';

type Props = {
    onClose: () => void;
    onGroupCreated: (conversationId: string) => void;
}

type FormData = {
    name: string;
    participants: string[];
    propertyAssociationAsParticipant: boolean;
}

const initialValues: FormData = {
    name: '',
    participants: [],
    propertyAssociationAsParticipant: false,
}

const validationSchema = Yup.object().shape({
    name: requiredValidator,
    participants: Yup.array().min(2, 'Du måste lägga till minst två deltagare')
})

const NewGroupModal: FunctionComponent<Props> = ({ onClose, onGroupCreated }) => {
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [suggestions, setSuggestions] = useState<TagSuggestion[]>();

    useEffect(() => {
        getFilteredMembers(
            apiBaseUrl,
            currentPropertyAssociationId,
            (response) => setSuggestions(response.members.map(mapToTagSuggestion)),
            () => setError('Kunde inte hämta användare')
        );
    }, [])

    if(suggestions === undefined) {
        return null;
    }

    const onSubmit = ({ name, participants, propertyAssociationAsParticipant }: FormData) => {
        setIsSubmitting(true);
        createConversationGroup(apiBaseUrl, currentPropertyAssociationId, name, propertyAssociationAsParticipant, participants, onSubmitted, onError);
    }

    const onSubmitted = ({ conversationId }: CreateConversationResponse) => {
        setIsSubmitting(false);
        onGroupCreated(conversationId);
    }

    const onError = () => {
        setIsSubmitting(false);
        setError('Kunde inte skapa gruppen');
    }

    return (
        <FormModal initialValues={initialValues} validationSchema={validationSchema} onClose={onClose} title="Skapa ny chattgrupp" submitText="Skapa" scrollable={false} isSubmitting={isSubmitting} onSubmit={onSubmit} visible={true}>
            {({ values, errors, touched }) => (
                <>
                    <TextField name="name" label="Ange ett namn för gruppen:" disabled={isSubmitting} values={values} errors={errors} touched={touched} />

                    <TagsField name="participants" label="Lägg till deltagare:" placeholder="Lägg till deltagare" suggestions={suggestions} />

                    <div className="mt-4">
                        <BooleanCheckBox name="propertyAssociationAsParticipant" label="Lägg till styrelsen som deltagare (kryssa i denna för att kunna skicka meddelanden till gruppen)" disabled={isSubmitting} />
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}
                </>
            )}
        </FormModal>
    )
}

const mapToTagSuggestion = (x: { userId: string; fullName: string; }): TagSuggestion => ({ value: x.userId, label: x.fullName });

export default NewGroupModal