import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getBooking } from '../../bookings/bookingService';
import { BookingDetails } from '../../bookings/models/BookingDetails';
import { ConfigContext } from '../../configuration/ConfigContext';
import { GetPinboardMessageResponse } from '../../pinboardMessages/models/GetPinboardMessageResponse';
import { getPinboardMessage } from '../../pinboardMessages/pinboardMessageService';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { ConversationContext } from '../ConversationContext';
import { getConversation, postConversationMessage } from '../conversationService';
import { ConversationMessageModel } from '../models/ConversationMessageModel';
import { ConversationModel } from '../models/ConversationModel';
import ConversationHeader from './ConversationHeader';
import ConversationMessage from './ConversationMessage';
import ImagePreview from './ImagePreview';
import ImageUpload from './ImageUpload';
import ReadStatus from './ReadStatus';
import EditGroupModal from '../modals/EditGroupModal';
import { Link } from 'react-router-dom';

type Props = {
    conversation: ConversationModel | null;
    fetchConversations?: () => void;
    showGroups?: boolean;
};

const BORDER_COMPENSATION = 2;
const MIN_HEIGHT = 50;

const Conversation: FunctionComponent<Props> = ({ conversation, fetchConversations, showGroups = false }) => {
    const ref = useRef<HTMLDivElement>(null);
    const shadowRef = useRef<HTMLTextAreaElement>(null);
    const { apiBaseUrl } = useContext(ConfigContext);
    const { lastUpdatedConversationId, lastMessageId } = useContext(ConversationContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [booking, setBooking] = useState<BookingDetails | undefined>();
    const [image, setImage] = useState<File | undefined>();
    const [isPosting, setIsPosting] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<ConversationMessageModel[]>([]);
    const [pinboardMessage, setPinboardMessage] = useState<GetPinboardMessageResponse | undefined>();
    const [showEditGroupModal, setShowEditGroupModal] = useState(false);
    const [textHeight, setTextHeight] = useState(MIN_HEIGHT);
    const { conversationId, bookingId, pinboardMessageId } = conversation || {};
    const user = conversation?.participants && conversation.participants.length > 0 ? conversation.participants[0]?.user : undefined;

    const onGroupUpdated = useCallback(() => {
        fetchConversations && fetchConversations();
        setShowEditGroupModal(false);
    }, [])

    useEffect(() => {
        if (conversationId) {
            getConversationFromId(conversationId);
        }
        
        setImage(undefined);
    }, [conversationId])
    
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo({ behavior: 'smooth', top: ref.current.scrollHeight });
        }
    }, [messages])

    useEffect(() => {
        if(!bookingId || showGroups) {
            setBooking(undefined);
            return;
        }

        getBooking(apiBaseUrl, currentPropertyAssociationId, bookingId, setBooking, () => { /**/ })
    }, [bookingId])

    useEffect(() => {
        if(!pinboardMessageId || showGroups) {
            setPinboardMessage(undefined);
            return;
        }

        getPinboardMessage(apiBaseUrl, currentPropertyAssociationId, pinboardMessageId, setPinboardMessage, () => { /**/ });
    }, [pinboardMessageId])

    useEffect(() => {
        if (lastUpdatedConversationId === conversationId) {
            getConversationFromId(conversationId);
        }
    }, [lastUpdatedConversationId, lastMessageId])

    const getConversationFromId = (id: string) => {
        getConversation(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            (data) => {
                setMessages(data?.conversation.messages || []);
            },
            () => {
                setMessages([]);
            }
        );
    }

    const submitMessage = () => {
        if ((message || image) && conversationId) {
            setIsPosting(true);
            postConversationMessage(
                apiBaseUrl,
                currentPropertyAssociationId,
                conversationId,
                message,
                image,
                false,
                () => {
                    setImage(undefined);
                    setMessage('');
                    setTextHeight(MIN_HEIGHT);
                    setIsPosting(false);
                },
                () => {
                    setMessage('');
                    setIsPosting(false);
                }
            );
        }
    };

    const handleInputChange = (inputEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = inputEvent.currentTarget.value;
        setMessage(value);

        if(!shadowRef.current) {
            return;
        }

        shadowRef.current.value = value;
        const scrollHeight = shadowRef.current.scrollHeight + BORDER_COMPENSATION;

        if(scrollHeight === textHeight) {
            return;
        }

        if(scrollHeight < MIN_HEIGHT) {
            setTextHeight(MIN_HEIGHT);
            return;
        }

        setTextHeight(scrollHeight);
    };

    return (
        <div className='conversations__item'>
            {!conversationId ? null : (
                <Fragment>
                    <ConversationHeader booking={booking} pinboardMessage={pinboardMessage} setShowEditGroupModal={setShowEditGroupModal} showGroups={showGroups} conversation={conversation} user={user} />

                    <div ref={ref} className='conversations__message-list'>
                        {messages.length > 0 ? messages.map((message) => <ConversationMessage message={message} key={message.created} />) : <h1 className='text-center p-3 m-0'>Inga meddelanden ännu</h1>}
                    </div>

                    { showGroups ? (
                        <div className='conversations__item-message'>
                            { conversation?.propertyAssociationAsParticipant ? (
                                <div className='conversations__info'>Styrelsen är en deltagare i den här gruppen, <Link to={`/messages/conversations/${conversationId}`}>Skicka meddelande här</Link></div>
                            ) : (
                                <div className='conversations__info'>Styrelsen är inte en deltagare i den här gruppen och kan därför inte skicka meddelanden. Administrera gruppen för att lägga till styrelsen som deltagare.</div>
                            )}
                        </div>
                    ) : (
                        <div className='conversations__item-message'>
                            <div className="d-flex justify-content-between align-items-center mb-1">
                                <ImageUpload setImage={setImage} fileName={image?.name} />
                                <ReadStatus conversationId={conversationId} isUnread={conversation?.unread} />
                            </div>
                            {image && <ImagePreview image={image} removeImage={() => setImage(undefined)} />}
                            <textarea ref={shadowRef} className="form-control conversations__shadow-input" />
                            <textarea
                                autoComplete='off'
                                className='form-control conversations__input'
                                disabled={isPosting}
                                onChange={handleInputChange}
                                onKeyDown={(e) => { if(e.key === 'Enter') { e.preventDefault(); submitMessage(); } }}
                                placeholder='Skicka ett meddelande'
                                style={{ height: `${textHeight}px`}}
                                value={message}
                            />
                            <button onClick={submitMessage} disabled={isPosting} className='conversations__send-button'></button>
                        </div>
                    )}

                    {showGroups && conversation && showEditGroupModal && <EditGroupModal conversation={conversation} onClose={() => setShowEditGroupModal(false)} onGroupUpdated={onGroupUpdated} />}
                </Fragment>
            )}
        </div>
    );
};

export default Conversation;
