import cx from 'classnames';
import { FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react';
import styles from './Accordion.module.scss';
import AnimateHeight from './AnimateHeight';

type Props = {
    children: string | ReactNode | ReactNode[];
    heading: string;
    startExpanded?: boolean;
};

const Accordion: FunctionComponent<Props> = ({children, heading, startExpanded = false}) => {
    const nodeRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(startExpanded);

    const toggleAccordion = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    return (
        <div className={cx(styles.container, {[styles.expanded]: isExpanded})}>
            <button onClick={toggleAccordion} className={styles.heading}>{heading}</button>
            <AnimateHeight duration={500} height={isExpanded ? 'auto' : 0}>
                <div ref={nodeRef} className={styles.content}>{children}</div>
            </AnimateHeight>
        </div>
    );
};

export default Accordion;
