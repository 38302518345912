import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { Fragment, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useAsyncError } from '../../../common/error/useAsyncError';
import { Asset } from '../models/Asset';
import { AssetNotification } from '../models/AssetNotification';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import FieldError from '../../forms/FieldError';
import FormikChangeSubscriber from '../../forms/FormikChangeSubscriber';
import TextField from '../../forms/TextField';
import { emailValidator } from '../../forms/validators';
import OverlayAlertModal from '../../modal/OverlayAlertModal';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import EditRecipientModal from '../modals/EditRecipientModal';
import { MESSAGES_SAVE_FAILED } from '../assetConstants';
import { isDraft } from '../assetHelpers';
import { getAssetNotifications, updateAssetNotification } from '../assetService';

declare module 'yup' {
    // tslint:disable-next-line
    interface ArraySchema<T> {
        unique(message?: any): ArraySchema<T>;
    }
}

type Props = {
    asset: Asset;
    gotoNext: () => void;
    gotoPrevious: () => void;
    renderCancelButton: () => any;
    updateValues: (values) => void;
}

Yup.addMethod(Yup.array, 'unique', function (message) {
    return this.test('unique', message, function (array) {
        const uniqueData = Array.from(
            new Set(array?.map((row) => row['email']?.toLowerCase())),
        );
        const isUnique = array?.length === uniqueData.length;
        if (isUnique) {
            return true;
        }
        const index = array?.findIndex(
            (row, i) => row['email']?.toLowerCase() !== uniqueData[i],
        ) || 0;
        if (array?.[index]['email'] === '') {
            return true;
        }
        return this.createError({
            path: `${this.path}.${index}.${'email'}`,
            message,
        });
    });
});

const validationSchema = Yup.object().shape({
    emailAtCancellation: Yup.boolean(),
    emailAtBookingMessage: Yup.boolean(),
    emailAtBooking: Yup.boolean(),
    notifiers: Yup.array().unique('E-posten är redan angiven').of(Yup.object().shape({
        email: emailValidator
    })),
    // Rule for checking that at least one notifier exists if either emailAtBooking, emailAtBookingMessage or emailAtCancellation is true
    atLeastOneNotifier: Yup.boolean().test('atLeastOneNotifier', 'Du måste ange minst en e-postadress', function () {
        const { emailAtBooking, emailAtBookingMessage, emailAtCancellation, notifiers } = this.parent;
        if (emailAtBooking || emailAtBookingMessage || emailAtCancellation) {
            return notifiers.length > 0;
        }

        return true;
    }),
})

const AssetNotifications: FunctionComponent<Props> = ({ asset, gotoNext, gotoPrevious, renderCancelButton, updateValues }) => {
    const throwAsyncError = useAsyncError()
    const formikRef = useRef<FormikProps<any>>();
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const [error, setError] = useState<string | undefined>();
    const [saved, setSaved] = useState<boolean>();
    const [showEditRecipientModal, setShowEditModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [assetNotification, setAssetNotification] = useState<AssetNotification>({
        emailAtBooking: false,
        emailAtCancellation: false,
        emailAtBookingMessage: false,
        notifiers: []
    })

    const onSuccessGetAssetNotification = (payload) => {
        setAssetNotification(payload)
    }
    const fetchAssetNotifications = () => getAssetNotifications(apiBaseUrl, currentPropertyAssociationId, asset.assetId, onSuccessGetAssetNotification, () => throwAsyncError('Kunde inte hämta information om notiser.'))
    useEffect(() => {
        fetchAssetNotifications()
    }, [])

    const handleSubmitClick = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }
    const onNextClick = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const handleSubmit = ({ emailAtBooking, emailAtCancellation, emailAtBookingMessage, notifiers }: AssetNotification) => {
        const notifiersList = notifiers.map(n => n.email)
        updateAssetNotification(apiBaseUrl, currentPropertyAssociationId, asset.assetId, { emailAtBooking, emailAtCancellation, emailAtBookingMessage, notifiers: notifiersList },
            () => {
                setSaved(true)
                if (isDraft(asset.status)) { handleNext() }
            },
            () => {
                setError(MESSAGES_SAVE_FAILED)
            },
        )
    }
    const handleNext = () => {
        gotoNext();
    }

    if (!assetNotification) { return null }
    return (
        <Fragment>
            <div className="paper__content  paper__main">
                <Formik initialValues={assetNotification}
                    innerRef={formikRef as any}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({ values, errors, touched }) => {
                        const formProps = { values, errors, touched };
                        return (
                            <Form>
                                <FormikChangeSubscriber onChange={() => setSaved(false)} />
                                <strong>När ska notiser skickas ut?</strong>
                                <p className="mb-3">Välj vid vilket/vilka tillfällen som en notis ska skickas ut till styrelsen</p>
                                <BooleanCheckBox name="emailAtBooking" label="Vid bokningen av resursen" />
                                <BooleanCheckBox name="emailAtCancellation" label="Vid avbokning av resursen" />
                                <BooleanCheckBox name="emailAtBookingMessage" label="Vid meddelanden om bokningen" />
                                <FieldError name="atLeastOneNotifier" extraClassName="mb-3" ignoreTouched={true} {...formProps} />
                                <FieldArray
                                    name="notifiers"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.notifiers.map((n, index) => (
                                                <div key={index} className="container">
                                                    <div className="row">
                                                        <div className="col-7 col-md-6 p-0">
                                                            <TextField name={`notifiers[${index}].email`} {...formProps} />
                                                        </div>
                                                        <div className="col-5 col-md-4 ">
                                                            {index > 0 && <button style={{ marginTop: '4px' }} className="btn btn-secondary" type="button" onClick={() => arrayHelpers.remove(index)}> Ta bort
                                                            </button>}
                                                        </div>

                                                    </div>
                                                </div>
                                            ))}
                                            <button type="button"
                                                className="btn btn-lg btn-plus btn-primary"
                                                onClick={() => arrayHelpers.push({ email: '' })}>Lägg till mottagare</button>
                                        </div>
                                    )}
                                />
                                {error && <div className="alert alert-danger">{error}</div>}
                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <div className="paper__buttons">
                {renderCancelButton()}
                <div className="paper__primary-buttons">
                    <button type="button" className="btn btn-lg btn-secondary btn-prev" onClick={gotoPrevious}>Tillbaka</button>
                    {isDraft(asset.status) ? (
                        <button type="button" className="btn btn-lg btn-primary btn-next" onClick={onNextClick}>Nästa</button>
                    ) : (
                        <button className="btn btn-lg btn-primary" type="submit" disabled={saved} onClick={handleSubmitClick}>{saved ? 'Sparad' : 'Spara'}</button>
                    )}
                </div>
            </div>

            <EditRecipientModal updateValues={updateValues} initialValues={asset} visible={showEditRecipientModal} onClose={() => setShowEditModal(false)} />
            <OverlayAlertModal heading="Det saknas information" text="Det verkar som att viss obligatorisk information om resursen saknas. När du fyllt i all information kommer du kunna gå vidare till nästa steg." visible={showErrorModal} onClick={() => setShowErrorModal(false)} />
        </Fragment>
    )
}

export default AssetNotifications