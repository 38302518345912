import { CreatePinboardMessageRequest } from './CreatePinboardMessageRequest';
import { GetPinboardMessageResponse } from './GetPinboardMessageResponse';
import { UpdatePinboardMessageRequest } from './UpdatePinboardMessageRequest';

export type PinboardMessageFormModel = {
    id: string;
    title: string;
    text: string;
    filePaths: string[];
    canUserAnswer: string;
    target: string;
}

export const mapResponseToForm = (pinboardMessage: GetPinboardMessageResponse): PinboardMessageFormModel => ({
    id: pinboardMessage.pinboardMessageId,
    title: pinboardMessage.title,
    text: pinboardMessage.text?.replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/<br\s*\/?>/mg, '\n') || '',
    filePaths: pinboardMessage.filePaths,
    canUserAnswer: pinboardMessage.usersCanRespond ? 'ja' : 'nej',
    target: pinboardMessage.propertyAssociationArea?.propertyAssociationAreaId || 'all',
})

export const mapFormToCreateRequest = (form: PinboardMessageFormModel): CreatePinboardMessageRequest => ({
    title: form.title,
    text: form.text?.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br>') || '',
    isPinned: false,
    usersCanRespond: form.canUserAnswer === 'ja',
    propertyAssociationAreaId: form.target === 'all' ? undefined : form.target,
})

export const mapFormToUpdateRequest = (form: PinboardMessageFormModel): UpdatePinboardMessageRequest => ({
    title: form.title,
    text: form.text?.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br>') || '',
    isPinned: false,
    usersCanRespond: form.canUserAnswer === 'ja',
})
