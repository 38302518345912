import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ErrorMessage from '../../common/error/ErrorMessage';
import { ConfigContext } from '../configuration/ConfigContext';
import PaperLayout from '../layout/PaperLayout';
import { PropertyAssociationContext } from '../propertyAssociation/PropertyAssociationContext';
import { withPropertyAssociation } from '../propertyAssociation/withPropertyAssociation';
import { ToastContext } from '../toaster/ToastContext';
import { ToastMessageType } from '../toaster/models/ToastMessageType';
import PinboardMessageForm from './components/PinboardMessageForm';
import { PinboardMessageFormModel, mapFormToUpdateRequest, mapResponseToForm } from './models/PinboardMessageFormModel';
import { getPinboardMessage, updatePinboardMessage } from './pinboardMessageService';

const EditPinboardMessage: FunctionComponent = () => {
    const history = useHistory();
    const { id } = useParams<any>();
    const { apiBaseUrl } = useContext(ConfigContext);
    const { currentPropertyAssociationId } = useContext(PropertyAssociationContext);
    const { addToast } = useContext(ToastContext);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [values, setValues] = useState<PinboardMessageFormModel | undefined>();

    const loadMessage = () => {
        getPinboardMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            (data) => {
                setValues(mapResponseToForm(data));
            },
            () => {
                setErrorMessage('Kunde inte ladda meddelandet.');
                setValues(undefined);
            }
        );
    };

    const handleValidSubmit = useCallback((messageForm: PinboardMessageFormModel) => {
        updatePinboardMessage(
            apiBaseUrl,
            currentPropertyAssociationId,
            id,
            mapFormToUpdateRequest(messageForm),
            () => {
                addToast('Meddelandet har sparats.');
                history.push(`/adverts/information/${id}`);
            },
            () => {
                addToast('Kunde ej uppdatera meddelandet.', undefined, ToastMessageType.Error);
            }
        );
    }, [id]);

    useEffect(() => {
        if (!id) {
            return;
        }
        
        loadMessage();
    }, [id]);

    if(errorMessage) {
        return <ErrorMessage message={errorMessage} />
    }

    if(!values) {
        return null
    }

    return (
        <PaperLayout 
            heading="Hantera meddelande" 
            tightenUp={true} 
            mainClass="px-0 pt-3"
            preamble={(
                <>
                    <strong>Information till boende</strong>
                    <p>Publicerade meddelanden ses av fastighetens boende på anslagstavlan i Mitt Boende-appen. Styrelsen står som avsändare.</p>
                </>
            )}
        >
            <PinboardMessageForm onCancel={() => history.push(`/adverts/information/${id}`)} messageForm={values} onValidSubmit={handleValidSubmit} />
        </PaperLayout>
    );
}

export default withPropertyAssociation(EditPinboardMessage)