import { buildQueryString, deleteToApi, getFromApi, postToApi, putToApi } from '../../common/utils/apiHelper';
import { CreatePinboardMessageRequest } from './models/CreatePinboardMessageRequest';
import { GetPinboardMessageResponse } from './models/GetPinboardMessageResponse';
import { GetPinboardMessageStatisticsResponse } from './models/GetPinboardMessageStatisticsResponse';
import { GetPinboardMessagesResponse } from './models/GetPinboardMessagesResponse';
import { UpdatePinboardMessageRequest } from './models/UpdatePinboardMessageRequest';

export const getPinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, onSuccess: (data: GetPinboardMessageResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}`, onSuccess, onFail);
}

export const getPinboardMessages = (apiBaseUrl: string, propertyAssociationId: string, areaId: string | undefined, onSuccess: (data: GetPinboardMessagesResponse) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages${buildQueryString({ areaId })}`, onSuccess, onFail);
}

export const getStatistics = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, onSuccess: (data: GetPinboardMessageStatisticsResponse ) => void, onFail: () => void): void => {
    getFromApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}/statistics`, onSuccess, onFail);
}

export const setSettings = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, usersCanRespond: boolean, onSuccess: () => void, onFail: () => void): void => {
    const settings = { usersCanRespond };
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}/settings`, settings, onSuccess, onFail);
}

export const createPinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, data: CreatePinboardMessageRequest, onSuccess: (id: string) => void, onFail: () => void): void => {
    postToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages`, data, onSuccess, onFail);
}

export const updatePinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, data: UpdatePinboardMessageRequest, onSuccess: () => void, onFail: () => void): void => {
    putToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}`, data, onSuccess, onFail);
}

export const deletePinboardMessage = (apiBaseUrl: string, propertyAssociationId: string, pinboardMessageId: string, onSuccess: () => void, onFail: () => void): void => {
    deleteToApi(`${apiBaseUrl}/propertyassociations/${propertyAssociationId}/pinboardmessages/${pinboardMessageId}`, onSuccess, onFail);
}
