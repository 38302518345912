import { Form, Formik } from 'formik';
import { FunctionComponent, useContext, useState } from 'react';
import * as Yup from 'yup';
import { Asset } from '../models/Asset';
import { ConfigContext } from '../../configuration/ConfigContext';
import BooleanCheckBox from '../../forms/BooleanCheckBox';
import { PropertyAssociationContext } from '../../propertyAssociation/PropertyAssociationContext';
import { getAsset, publishAsset, refreshAssets } from '../assetService';

type Props = {
    gotoNext: () => void;
    gotoPrevious: () => void;
    renderCancelButton: () => any;
    asset: Asset;
    updateValues: (values: Asset) => void;
}

type FormData = {
    accept: boolean;
}

const initialValues: FormData = {
    accept: false,
}

const validationSchema = Yup.object().shape({
    accept: Yup.bool().oneOf([true], 'Field must be checked'),
})

const AssetReadyToPublish: FunctionComponent<Props> = ({ gotoNext, gotoPrevious, renderCancelButton, asset, updateValues }) => {
    const { currentPropertyAssociationId, currentArea } = useContext(PropertyAssociationContext);
    const { apiBaseUrl } = useContext(ConfigContext);
    const [error, setError] = useState<string | undefined>();

    const publish = () => {
        setError(undefined);

        publishAsset(apiBaseUrl, currentPropertyAssociationId, asset.assetId,
            (assetId: string) => {
                getAsset(apiBaseUrl, currentPropertyAssociationId, assetId, (data: Asset) => {
                    updateValues(data);
                    refreshAssets(apiBaseUrl, currentPropertyAssociationId, currentArea?.propertyAssociationAreaId);
                    gotoNext();
                }, () => setError('Kunde inte uppdatera resursen.'))
            },
            () => { setError('Resursen kunde inte publiceras. Försök igen.') }
        )
    }

    return (
        <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validationSchema} validateOnMount={true} onSubmit={publish}>
            {({ isValid }) => (
                <Form className="paper__form">
                    <div className="paper__content  paper__main">
                        <strong>Redo att publicera?</strong>
                        <p>
                            Resursen är nu redo att publiceras. Gå gärna igenom stegen och granska att allt innehåll är korrekt. Om ni skulle vilja ändra något senare går det alltid att redigera innehållet även efter publicering.
                        </p>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <div className="--limit-width">
                            <BooleanCheckBox name="accept" label="Bostadsrättsföreningens styrelse ansvarar för att det publicerade innehållet representerar den bokningsbara resursen på ett sanningsenligt sätt, samt att beskrivningen av den inte innehåller olämpliga eller stötande element. Personer på bilder måste även ha samtyckt till publicering." />
                        </div>
                    </div>
                    <div className="paper__buttons">
                        {renderCancelButton()}
                        <div className="paper__primary-buttons">
                            <button type="button" className="btn btn-lg btn-secondary btn-prev" onClick={gotoPrevious}>Tillbaka</button>
                            <button type="submit" className="btn btn-lg btn-success" disabled={!isValid} onClick={publish}>Publicera</button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default AssetReadyToPublish