import { FunctionComponent, useEffect } from 'react'
import { scrollElementTo } from '../../../common/utils/domUtils'
import Button from '../../forms/Button'
import PaperLayout from '../../layout/PaperLayout'
import InfoBox from '../../shared/InfoBox'

type Props = {
    onGetStarted: () => void
    onGoBack: () => void
}

const SwishSupplierInformation: FunctionComponent<Props> = ({onGetStarted, onGoBack}) => {
    useEffect(() => {
        scrollElementTo('content', 0);
    }, []);
    
    return (
        <PaperLayout
            heading="Riksbyggen som teknisk leverantör"
            preamble="Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius. Adipiscing pretium in quam vitae. Ac eleifend tincidunt sapien pellentesque sem dui. Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius."
            mainClass="pb-5 pt-1"
        >
            <h2>Om du redan har ett Swish-nummer</h2>
            <p>Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius. Adipiscing pretium in quam vitae. Ac eleifend tincidunt sapien pellentesque sem dui. Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius.</p>

            <h2>Ange Riksbyggen som teknisk leverantör</h2>
            <p>Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius. Adipiscing pretium in quam vitae. Ac eleifend tincidunt sapien pellentesque sem dui. Lorem ipsum dolor sit amet consectetur. Suspendisse mattis netus leo magna et. Neque augue in pellentesque massa iaculis sagittis varius.</p>

            <InfoBox className="d-flex justify-content-between align-items-center">
                <h2 className='my-0'>Vill du ha hjälp att komma igång med Swish?</h2>
                <Button type="button" text="Kom igång" onClick={onGetStarted} />
            </InfoBox>

            <Button type="button" text="Gå tillbaka" isPrimary={false} additionalClassName="btn-secondary btn-prev" onClick={onGoBack} />
        </PaperLayout>
    )
}

export default SwishSupplierInformation